import React from 'react';

function Label({ id, label, hidden, required }) {
  if (!label) {
    return null;
  }
  const className = hidden ? 'sr-only' : 'block text-md font-medium text-gray-700 mb-1';
  return (
    <label htmlFor={id} className={className}>
      {label}{`${required ? ' *' : ''}`}
    </label>
  );
}

export default Label;
