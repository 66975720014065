import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Form from './form';
import { validatePhone, formatPhoneFromServer } from '../../utils/helpers';
import { useNotifications } from '../../contexts/notifications-provider';
import { getApiRequest, postApiRequest } from '../../utils/request';
import Alert from '../alerts/alert';
import Loadable from '../data/loadable';

function defaultFromType(type) {
  switch(type) {
    case 'date':
      return null;
    case 'checkboxes':
      return [];
    default:
      return '';
  }
}

function initialValueFromType(type, name, entity) {
  switch (type) {
    case 'phone':
      return (entity && formatPhoneFromServer(entity[name])) || defaultFromType(type);
    default:
      return (entity && entity[name]) || defaultFromType(type);
  }
}

function requiredMessage(label) {
  return `${label} is a required field.`;
}

function validationSchemaFromType(type, required, label) {
  switch (type) {
    case 'date':
      return required ? Yup.date().typeError('Must be a valid date.') : Yup.date().typeError('Must be a valid date.').nullable();
    case 'select':
    case 'select-lookup':
    case 'cloudinary-multiple-upload':
      return required ? Yup.mixed().required(requiredMessage(label)) : Yup.mixed();
    case 'checkbox':
      return required ? Yup.boolean().oneOf([true], 'You must agree to continue.') : Yup.boolean();
    case 'checkboxes':
      return required ? Yup.array().min(1, 'You must select at least one option.') : Yup.array();
    case 'email':
      return required ? Yup.string().email('Invalid email address.').required(requiredMessage(label)) : Yup.string().email('Invalid email address.');
    case 'phone':
      return required
        ? Yup.string()
          .test(
            'valid-phone',
            'Must be a valid phone number.',
            (val) => val === '' || typeof val === 'undefined' || validatePhone(val)
          )
          .required(requiredMessage(label))
        : Yup.string()
          .test(
            'valid-phone',
            'Must be a valid phone number.',
            (val) => val === '' || typeof val === 'undefined' || validatePhone(val)
          );
      default:
        return required ? Yup.string().required(requiredMessage(label)) : Yup.string();
  }
}

// Loop over fields and build validations as well as initial values
function prepareSchemaAndValues(fields, entity) {
  const initialValues = {};
  const validationSchema = {};
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    const { name, type, label, validations, required, initialValue } = field;
    if (initialValue) {
      initialValues[name] = initialValue(entity);
    } else {
      initialValues[name] = initialValueFromType(type, name, entity);
    }
    if (validations) {
      validationSchema[name] = validations();
    } else {
      validationSchema[name] = validationSchemaFromType(type, required, label);
    }
  }

  return [initialValues, validationSchema];
}

function GetForm({ fields, successRedirect, successHandler, noContainer, submitLabel }) {
  const navigate = useNavigate();
  const query = queryString.parse(window.location.search);

  const onSubmit = (values, actions) => {
    actions.setSubmitting(false);
    if (successRedirect) {
      const newQuery = queryString.stringify(values);
      const newParams = newQuery ? `?${newQuery}` : '';
      // We are just going to redirect the browser by updating the query params...
      navigate(`${successRedirect}${newParams}`);
    } else if (successHandler) {
      successHandler(values, actions);
    }
  };

  const [initialValues, validationSchema] = prepareSchemaAndValues(fields, query);

  const form = {
    initialValues,
    // @ts-ignore
    validationSchema: Yup.object(validationSchema),
    onSubmit,
    fields,
    submit: {
      label: submitLabel || 'Submit',
    },
  };

  return <Form noContainer={noContainer} {...form} />
}

function PostForm({ fields, submitLabel, successNotification, fetchErrorRedirect, fetchErrorMessage, fetchRoute, fetchHydrate, postRoute, successRedirect, successHandler, noContainer, beforePost }) {
  const [loading, setLoading] = useState(true);
  const [entity, setEntity] = useState(null);
  const [formError, setFormError] = useState(null);
  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const init = async () => {
      if (fetchRoute) {
        try {
          const fetched = await getApiRequest(fetchRoute);
          const hydrated = fetchHydrate ? fetchHydrate(fetched) : fetched;
          setEntity(hydrated);
        } catch (err) {
          if (err.response && err.response.status === 401) {
            return;
          }
          addNotification({
            type: 'error',
            body: fetchErrorMessage || 'There was an error loading the requested object.',
          });
          if (fetchErrorRedirect) {
            navigate(fetchErrorRedirect);
          }
        }
      }
      setLoading(false);  
    };

    init();
  }, [fetchRoute]);

  const onSubmit = async (values, actions) => {
    if (formError) {
      setFormError(null);
    }
    try {
      const valuesToSend = beforePost ? beforePost(values, actions, entity) : values;
      const result = await postApiRequest(postRoute, valuesToSend);
      addNotification({
        type: 'success',
        body: successNotification,
        timeout: 3000,
      });
      if (successRedirect) {
        navigate(successRedirect);
      } else if (successHandler) {
        successHandler(valuesToSend, actions, result);
      }
    } catch (err) {
      const { response: errorResponse } = err;
      if (errorResponse.status === 422) {
        addNotification({
          type: 'error',
          body: 'Please correct the errors to continue.',
          timeout: 4000,
        });
        const { data: errorData } = errorResponse;
        const { errors, error: genericError } = errorData || {};
        const newErrors = {};
        if (errors) {
          const errorFieldNames = Object.keys(errors);
          for (let i = 0; i < errorFieldNames.length; i++) {
            const errorFieldName = errorFieldNames[i];
            newErrors[errorFieldName] = errors[errorFieldName].join(' ');
          }
          actions.setErrors(newErrors);
        }
        if (genericError) {
          setFormError(genericError);
        }
      } else if (errorResponse.status !== 401) {
        addNotification({
          type: 'error',
          body: 'There was an unknown error, please try again.',
          timeout: 6000,
        });
      }
    }

    actions.setSubmitting(false);
  }
  
  const [initialValues, validationSchema] = prepareSchemaAndValues(fields, entity);


  const form = {
    initialValues,
    // @ts-ignore
    validationSchema: Yup.object(validationSchema),
    onSubmit,
    fields,
    submit: {
      label: submitLabel || 'Submit',
    },
  };

  return (
    <Loadable loading={loading}>
      {!!formError && <Alert type="error" body={formError} />}
      <Form noContainer={noContainer} {...form} />
    </Loadable>
  );

}

export default function AutoForm({ fields, type, submitLabel, successNotification, fetchErrorRedirect, fetchErrorMessage, fetchRoute, fetchHydrate, postRoute, successRedirect, successHandler, noContainer, beforePost }) {
  if (type === 'get') {
    return <GetForm fields={fields} successHandler={successHandler} successRedirect={successRedirect} noContainer={noContainer} submitLabel={submitLabel} />;
  }
  return (
    <PostForm
      fields={fields}
      submitLabel={submitLabel}
      successNotification={successNotification}
      fetchErrorRedirect={fetchErrorRedirect}
      fetchErrorMessage={fetchErrorMessage}
      fetchRoute={fetchRoute}
      fetchHydrate={fetchHydrate}
      postRoute={postRoute}
      successRedirect={successRedirect}
      successHandler={successHandler}
      noContainer={noContainer}
      beforePost={beforePost}
    />
  );
}