import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { AsYouType } from 'libphonenumber-js';

function Phone({ formik, name, id, placeholder, hasError }) {
  const classes = hasError ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
  const wrapperClasses = hasError ? 'relative rounded-md shadow-sm' : '';
  const ariaProps = hasError ? { 'aria-invalid': 'true', 'aria-describedby' : `${id}-error` } : { 'aria-describedby' : `${id}-description` };

  const changeHandler = (e) => {
    // Get a reference to our as you type function
    const asYouType = new AsYouType('US');
    // Let's confirm that we aren't trying to delete the ), if we are, we want to skip the as you type bit
    let value = asYouType.input(e.target.value);
    if (e.nativeEvent && e.nativeEvent.inputType === 'deleteContentBackward' && value && value.slice(-1) === ')') {
      // Just reset it to the original value, since we want allow the ) to be removed
      value = e.target.value;
    }
    formik.setFieldValue(name, value);
  };

  return (
    <div className={wrapperClasses}>
      <input
        {...formik.getFieldProps(name)}
        {...ariaProps}
        className={`block w-full rounded-md sm:text-sm ${classes}`}
        id={id}
        name={name}
        type="text"
        placeholder={placeholder}
        onChange={changeHandler}
      />
      {hasError && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      )}
    </div>
  );
}

export default Phone;
