import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

function Input({ formik, type, name, id, placeholder, hasError }) {
  const classes = hasError ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
  const wrapperClasses = hasError ? 'relative rounded-md shadow-sm' : '';
  const ariaProps = hasError ? { 'aria-invalid': 'true', 'aria-describedby' : `${id}-error` } : { 'aria-describedby' : `${id}-description` };
  // No password field used for login will show up in one of these forms, so set the autocomplete property to inform the browser to leave it alone
  const autocompleteProps = type === 'password' ? { autoComplete: 'new-password' } : {};
  return (
    <div className={wrapperClasses}>
      <input
        {...formik.getFieldProps(name)}
        {...ariaProps}
        {...autocompleteProps}
        className={`block w-full rounded-md sm:text-sm ${classes}`}
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
      />
      {hasError && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      )}
    </div>
  );
}

export default Input;
