import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PlusCircleIcon, XCircleIcon, SelectorIcon } from '@heroicons/react/outline';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { findIndexById } from '../../../utils/helpers';

function AddValueButton({ add, addLabel }) {
  return (
    <button onClick={(e) => { e.preventDefault(); add(); }} className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
      <PlusCircleIcon className="inline text-gray-500 w-5 h-5 mr-2" />{addLabel}
    </button>
  );
};

function SingleText({ id, value, onChange, placeholder, remove, reorder }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const classes = 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
  return (
    <div ref={setNodeRef} style={style} className="flex flex-row items-center">
      {!!reorder && (
        <div className="flex-shrink-0" {...attributes} {...listeners}>
          <SelectorIcon className="cursor-move h-5 w-5 mr-2" aria-hidden="true" />
        </div>
      )}
      <div className="flex-1">
        <input
          className={`block w-full rounded-md sm:text-sm ${classes}`}
          id={id}
          name={id}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(id, e.target.value)}
        />
      </div>
      <div className="flex-shrink">
        <button onClick={(e) => { e.preventDefault(); remove(id); }} className="ml-2 mt-2"><XCircleIcon className='w-6 h-6 text-gray-400' /></button>
      </div>
    </div>
  );
}

function TextList({ formik, name, placeholder, hasError, addLabel = 'Add', reorder = false }) {
  const sensors = useSensors(
    useSensor(PointerSensor),
  );
  
  const wrapperClasses = hasError ? 'relative rounded-md shadow-sm' : '';

  // Local copy of our formik values
  const localValues = formik.values[name];

  const updateLocalValues = (newLocalValues) => {
    // setLocalValues(newLocalValues);
    formik.setFieldValue(name, newLocalValues);
  }

  const add = () => {
    const newLocalValues = [
      ...localValues,
      {
        id: uuidv4(),
        value: '',
      },
    ];
    updateLocalValues(newLocalValues);
  };

  const remove = (id) => {
    const index = findIndexById(localValues, id);
    const newLocalValues = [...localValues];
    newLocalValues.splice(index, 1);
    updateLocalValues(newLocalValues);
  };


  const onChangeSingleText = (id, value) => {
    const index = findIndexById(localValues, id);
    const newLocalValues = [...localValues];
    newLocalValues[index] = { id, value };
    updateLocalValues(newLocalValues);
  };


  function handleDragEnd(event) {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      const oldIndex = findIndexById(localValues, active.id);
      const newIndex = findIndexById(localValues, over.id);
      const newLocalValues = arrayMove(localValues, oldIndex, newIndex);
      updateLocalValues(newLocalValues);
    }
  }



  return (
    <div className={`${wrapperClasses} space-y-4`}>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext 
          items={localValues}
          strategy={verticalListSortingStrategy}
        >
          {localValues.map(localValue => {
            const { id, value } = localValue;
            return <SingleText reorder={reorder} id={id} key={id} value={value} onChange={onChangeSingleText} placeholder={placeholder} remove={remove} />;
          })}
          </SortableContext>
        </DndContext>
      <div className="py-4">
        <AddValueButton add={add} addLabel={addLabel} />
      </div>
    </div>
  );
}

export default TextList;
