import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { SearchCircleIcon } from '@heroicons/react/solid';
import { getApiRequest } from '../../../utils/request';

function SelectedValue({ reset, label }) {
  return (
    <div className="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-blue-100 text-blue-700">
      {label}
      <button
        type="button"
        onClick={reset}
        className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white"
      >
        <span className="sr-only">Remove {label}</span>
        <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </div>
  )
};

const useDebouncedCallback = (func, wait) => {
  const timeout = useRef();

  return useCallback(
    (...args) => {
      const later = () => {
        clearTimeout(timeout.current);
        func(...args);
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
    },
    [func, wait]
  );
};

function SelectLookup({ formik, name, id, hasError, placeholder, lookupUrl }) {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [selected, setSelected] = useState(null);


  const chooseOption = (option) => {
    setOptions([]);
    setSearchValue('');
    formik.setFieldValue(name, option.value);
    setSelected(option);
  };

  const reset = () => {
    setSelected(null);
    setOptions([]);
    formik.setFieldValue(name, '');
    setSearchValue('');
  };

  const performSearch = useDebouncedCallback(async () => {
    if (!searchValue) {
      setOptions([]);
      setShowDropdown(false);
      return;
    }
    setSearching(true);
    setShowDropdown(true);
    try {
      const url = lookupUrl.includes('?') ? `${lookupUrl}&search=${searchValue}` : `${lookupUrl}?search=${searchValue}`;
      const results = await getApiRequest(url);

      if (results.options) {
        setOptions([
          ...results.options,
        ]);
      }
    } catch (err) {
      console.log(err, 'error fetching options');
      setOptions([]);
    }
    setSearching(false);

  }, 350);

  // Handle search
  useEffect(() => {
    performSearch();
  }, [searchValue]);

  // Handle initial value loading
  const { initialValues} = formik || {};
  const defaultValue = initialValues ? initialValues[name] : null;
  useEffect(() => {
    if (!defaultValue) {
      return;
    }
    const fetchDefault = async () => {
      setLoading(true);
      try {
        const url = lookupUrl.includes('?') ? `${lookupUrl}&value=${defaultValue}` : `${lookupUrl}?value=${defaultValue}`;
        const results = await getApiRequest(url);
        if (results.options) {
          setSelected(results.options[0]);
        }
      } catch (err) {
        console.log(err, 'error loading default value');
      }
      setLoading(false);
    }
    fetchDefault();
  }, [defaultValue]);


  if (selected) {
    return (
      <SelectedValue reset={reset} {...selected} />
    );
  }

  const inputClasses = hasError ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
  const wrapperClasses = hasError ? 'relative rounded-md shadow-sm' : 'relative';
  const ariaProps = hasError ? { 'aria-invalid': 'true', 'aria-describedby' : `${id}-error` } : { 'aria-describedby' : `${id}-description` };
  return (
    <div className="relative">
      <div className={wrapperClasses}>
        <input
          {...ariaProps}
          className={`block w-full rounded-md sm:text-sm pr-10 ${inputClasses}`}
          id={id}
          name={name}
          type="text"
          disabled={loading}
          placeholder={loading ? 'Loading...' : placeholder}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onBlur={() => setShowDropdown(false)}
          onFocus={performSearch}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <SearchCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
        </div>
      </div>

      <Transition
        show={showDropdown}
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ul className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          {!searching && !!options.length && options.map((option) => (
            <li
              key={option.value}
              className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-200"
              onClick={() => chooseOption(option)}
            >
              <div className="flex">
                <span className="font-normal truncate">
                  {option.label}
                </span>
                {!!option.subLabel && (
                  <span className="text-gray-500 ml-2 truncate">
                    {option.subLabel}
                  </span>
                )}
              </div>
            </li>
          ))}
          {(searching || !options.length) && (
            <li className="cursor-default select-none relative py-2 pl-3 pr-9 text-gray-700">
              {searching && <>Searching...</>}
              {!searching && <>No results matched your search.</>}
            </li>
          )}
        </ul>
      </Transition>
    </div>
  );
}

export default SelectLookup;
