import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import queryString from 'query-string';
import { LockClosedIcon } from '@heroicons/react/solid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../contexts/auth-provider';
import Container from '../components/layout/default/container';
import Panel from '../components/common/panel';

const Login = () => {
  const navigate = useNavigate();
  const { signout, signin, user } = useAuth();

  useEffect(() => {
    if (user) {
      signout();
    }
  }, []);


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address.').required('Email is a required field.'),
      password: Yup.string().required('Password is a required field.'),
    }),
    onSubmit: async (values, actions) => {
      const { email, password } = values;
      try {
       await signin(email, password);
       const parsed = window.location.search ? queryString.parse(window.location.search) : {};
       const redir = parsed && parsed.redir ? parsed.redir : '/dashboard';
       // @ts-ignore
       navigate(redir);
      } catch (err) {
        actions.setErrors({
          email: 'Invalid email or password, please try again.',
        });
      }
      actions.setSubmitting(false);
    },
  });

  const regularInputClasses = 'border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500';
  const errorInputClasses = 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';

  const hasEmailError = formik.touched.email && formik.errors.email;
  const hasPasswordError = formik.touched.password && formik.errors.password;

  return (

    <Container classes="bg-gray-50">
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <Panel>
            <div className="py-6 px-4">
              <div>
                <Link to="/">
                  <img
                    className="mx-auto h-12 w-auto"
                    src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg"
                    alt="CPipe"
                  />
                </Link>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Login to your account</h2>
                
              </div>
              <form className="mt-8 space-y-6" onSubmit={formik.handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      {...formik.getFieldProps('email')}
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className={`${hasEmailError ? errorInputClasses : regularInputClasses} appearance-none rounded-none relative block w-full px-3 py-2 border rounded-t-md focus:outline-none focus:z-10 sm:text-sm`}
                      placeholder="Email address"
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <input
                      {...formik.getFieldProps('password')}
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      className={`${hasPasswordError ? errorInputClasses : regularInputClasses} appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b-md focus:outline-none focus:z-10 sm:text-sm`}
                      placeholder="Password"
                    />
                    {hasEmailError && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {formik.errors.email}
                      </p>
                    )}
                    {hasPasswordError && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {formik.errors.password}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="text-sm">
                    <Link to="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Forgot your password?
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                    </span>
                    Login
                  </button>
                </div>
              </form>
            </div>
          </Panel>
        </div>
      </div>
    </Container>
  );
};

export default Login;
