function getColor(type) {
  switch (type) {
    case 'error':
    case 'danger':
      return {
        titleBg: 'bg-red-500',
        bodyBorder: 'border-red-400',
        bodyBg: 'bg-red-100',
        bodyText: 'text-red-700',
      };

    case 'success':
      return {
        titleBg: 'bg-green-500',
        bodyBorder: 'border-green-400',
        bodyBg: 'bg-green-100',
        bodyText: 'text-green-700',
      };
    case 'warning':
      return {
        titleBg: 'bg-orange-500',
        bodyBorder: 'border-orange-400',
        bodyBg: 'bg-orange-100',
        bodyText: 'text-orange-700',
      };

    case 'info':
    default:
      return {
        titleBg: 'bg-blue-500',
        bodyBorder: 'border-blue-400',
        bodyBg: 'bg-blue-100',
        bodyText: 'text-blue-700',
      };
  }
}

function Alert({ title = null, body, type }) {
  const colors = getColor(type);

  return (
    <div role="alert" className="my-4 max-w-lg mx-auto">
      {title && <div className={`${colors.titleBg} text-white font-bold rounded-t px-4 py-2`}>{title}</div>}
      <div className={`border ${title ? 'border-t-0' : 'rounded-t'} ${colors.bodyBorder} rounded-b ${colors.bodyBg}  px-4 py-3 ${colors.bodyText} `}>
        <p>{body}</p>
      </div>
    </div>
  );
}

export default Alert;
