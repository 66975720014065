import { useParams } from 'react-router-dom';
import Container from '../components/layout/default/container';
import headerImage from '../assets/header-photo.png';
import Panel from '../components/common/panel';
import AutoForm from '../components/form/auto-form';
import { statesOptions } from '../utils/helpers';

function arrayToOptions(arr){
  return arr.map(item => ({ value: item, label: item }));
}

export default function Home() {
  const { code } = useParams();

  const locations = [
    '35 South Main St, Travelers Rest - Sidewall',
    '99 Cleveland St, Greenville - Sidewall',
    '3598 Pelham Rd, Greenville - Sidewall',
    '117 SE Main St, Simpsonville - Sidewall',
    '2615 Woodruff Rd, Five Forks - Sidewall',
    'Monkey Wrench Smokehouse',
    'Sidewall Pastry Kitchen',
    'Any/All',
  ];
  const locationOptions = arrayToOptions(locations);

  const positions = [
    'Server',
    'Bartender',
    'Host',
    'Food Runner',
    'Busser',
    'Dishwasher',
    'Cook',
    'FOH Manager',
    'BOH Manager',
    'General Manager',
  ];
  const positionOptions = arrayToOptions(positions);

  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const dayOptions = arrayToOptions(days);

  const yesNoOptions = [
    {
      value: '',
      label: 'Select...',
    },
    {
      value: '1',
      label: 'Yes',
    },
    {
      value: '0',
      label: 'No',
    },
  ];
  

  const fields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      required: true,
      placeholder: 'Your full name.',
    },
    {
      id: 'email',
      name: 'email',
      label: 'Email Address',
      type: 'email',
      required: true,
    },
    {
      name: 'phone',
      label: 'Phone Number',
      type: 'phone',
      helpText: 'Must be a mobile phone.',
      required: true,
    },
    {
      name: 'address',
      label: 'Address',
      type: 'text',
      required: true,
    },
    {
      name: 'city',
      label: 'City',
      type: 'text',
      required: true,
    },
    {
      name: 'state',
      label: 'State',
      type: 'select',
      required: true,
      options: statesOptions(),
    },
    {
      name: 'zip',
      label: 'Zip',
      type: 'text',
      required: true,
    },
    {
      name: 'birthday',
      label: 'Birthday',
      type: 'date',
      required: true,
      helpText: 'Some of the jobs in our company have minimum age requirements, so we need to know your date of birth when considering you for a position.',
    },
    // {
    //   name: 'employee_referral',
    //   label: 'Did a Sidewall Employee refer you?  If so, please enter their name below.',
    //   type: 'text',
    // },
    {
      name: 'locations',
      type: 'checkboxes',
      options: locationOptions,
      label: 'Which locations are you interested in?',
      required: true,
      helpText: 'Check all that apply.',
    },
    {
      name: 'positions',
      type: 'checkboxes',
      options: positionOptions,
      label: 'What position are you interested in with our company?',
      required: true,
      helpText: 'Check all that apply.',
    },
    {
      name: 'hours_per_week',
      label: 'How many hours do you want to work each week?',
      type: 'text',
      required: true,
    },

    {
      name: 'why_interested',
      label: 'What interests you in this position?',
      type: 'textarea',
      required: true,
    },
    {
      name: 'qualifications',
      label: 'What qualifications do you have for this position?',
      type: 'textarea',
      required: true,
    },

    {
      name: 'available_days',
      type: 'checkboxes',
      options: dayOptions,
      label: 'What is your availability?',
      required: true,
      helpText: 'Check all that apply.',
    },
    {
      name: 'hours_available',
      label: 'Hours Available?',
      type: 'textarea',
      required: true,
      helpText: 'Write your ongoing availability. For example, if you are available from 8am to 11pm on Mondays, write 8am - 11pm in the blank line with the corresponding days. If you are not available on a specific day, write “not available” along with the day.',
    },
    {
      name: 'reliable_transportation',
      label: 'Do you have reliable transportation so that you will always be able to arrive on time for your shift?',
      type: 'select',
      options: yesNoOptions,
      required: true,
    },
    {
      name: 'comply_no_smoking',
      label: 'At Sidewall Hospitality Group, we have a no smoking policy. We do not permit smoking in the building or anywhere on the premises and we do not provide smoke breaks. Will you be able to comply with this policy?',
      type: 'select',
      options: yesNoOptions,
      required: true,
    },
    {
      name: 'comply_no_drugs',
      label: 'Can you comply with a zero tolerance drug policy at work and on the premises?',
      type: 'select',
      options: yesNoOptions,
      required: true,
    },
    
    {
      name: 'good_place_to_work',
      label: 'What makes a restaurant a great place to work?',
      type: 'textarea',
      required: true,
    },
    {
      name: 'favorite_topping_bbq_ice_cream',
      label: 'What\'s your favorite pizza topping or favorite ice cream or bbq item?',
      type: 'textarea',
      required: true,
    },
    {
      name: 'most_fun_at_work',
      label: 'What\'s the most fun you\'ve had at work?',
      type: 'textarea',
      required: true,
    },
    {
      name: 'comments',
      label: 'Any other comments or anything you would like to tell us?',
      type: 'textarea',
    },
  ];


  const jobAppForm = {
    type: 'post',
    postRoute: `/application`,
    beforePost: (values) => {
      // Add our code, if we have one
      return {
        ...values,
        referral_code: code || null,
      };
    },
    successRedirect: `/thank-you`,
    successNotification: 'Your job application was posted!',
    fields,
    centerButton: true,
    submitLabel: `Complete Job Application`,
  };

  return (
    <Container classes="bg-gray-50">
      <div className="py-20 max-w-3xl mx-auto">
        <img src={headerImage} className="w-full" alt="Join Our Team" />
        <p className="text-indigo-900 text-lg my-8">
          We are thrilled that you are interested in working at Sidewall Pizza Company or Monkey Wrench Smokehouse! If you love
          teamwork, pizza, bbq, community, and learning, you might be a great fit for our team of servers, bartenders, hosts, runners,
          bussers, pizza makers, cooks, dishwashers, and managers.
        </p>
        <div className="max-w-2xl mx-auto">
          <Panel>
            <AutoForm {...jobAppForm} />
          </Panel>
        </div>
      </div>
    </Container>
  );
}