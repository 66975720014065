import React, { useState, useEffect } from 'react';
import { getApiRequest } from '../../../utils/request';

function Select({ formik, name, id, hasError, options, optionsUrl, getOptionsUrl, dynamicOptions }) {
  const [localOptions, setLocalOptions] = useState([{ value: '', label: 'Loading...' }]);
  const [lastOptionsUrl, setLastOptionsUrl] = useState(null);

  const remoteLoadOptions = async (url) => {
    setLastOptionsUrl(url);
    if (!url) {
      setLocalOptions([]);
      return;
    }
    try {
      const results = await getApiRequest(url);
      if (results.options) {
        setLocalOptions([
          {
            value: '',
            label: 'Select...',
          },
          ...results.options,
        ]);
      }
    } catch (err) {
      console.log(err, 'error fetching options');
    }
  }

  useEffect(() => {
    // If the options url is derived, we want to derive it, skip this step
    if (getOptionsUrl) {
      return;
    }
    if (options) {
      setLocalOptions(options);
    } else if (optionsUrl) {
      remoteLoadOptions(optionsUrl);
    }
  }, [optionsUrl, options, setLocalOptions, getOptionsUrl]);

  // In case we have dynamic options, we need to potentially re-render them each time.  Pass existing options and form values to the function
  useEffect(() => {
    if (dynamicOptions) {
      dynamicOptions(formik, localOptions, setLocalOptions);
    }
  });

  // If the local options url has changed, it means that we are deriving it.  If it's new, load options
  const localOptionsUrl = getOptionsUrl && getOptionsUrl(formik);
  useEffect(() => {
    if (getOptionsUrl && lastOptionsUrl !== localOptionsUrl) {
      remoteLoadOptions(localOptionsUrl);
    }
  }, [localOptionsUrl, getOptionsUrl]);

  const classes = hasError ? 'border-red-300 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500';
  const ariaProps = hasError ? { 'aria-invalid': 'true', 'aria-describedby' : `${id}-error` } : { 'aria-describedby' : `${id}-description` };
  return (
    <select
      {...formik.getFieldProps(name)}
      {...ariaProps}
      className={`mt-1 block w-full pl-3 pr-10 py-2 text-base sm:text-sm rounded-md ${classes}`}
      id={id}
      name={name}
    >
      {localOptions.map((option) => (<option key={`${option.value}_${option.label}`} value={option.value}>{option.label}</option>))}
    </select>
  );
}

export default Select;
