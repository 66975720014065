import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-provider';

const Logout = () => {
  const navigate = useNavigate();
  const { signout, user } = useAuth();

  useEffect(() => {
    if (user) {
      signout();
    }
    navigate('/');
  }, []);


  return null;
};

export default Logout;
