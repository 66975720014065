import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/solid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../contexts/auth-provider';
import Container from '../components/layout/default/container';
import Panel from '../components/common/panel';
import Alert from '../components/alerts/alert';

const ForgotPassword = () => {
  const [sent, setSent] = useState(false);
  const { sendForgotPassword } = useAuth();


  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address.').required('Email is a required field.'),
    }),
    onSubmit: async (values, actions) => {
      const { email } = values;
      try {
       await sendForgotPassword(email);
       setSent(true);
      } catch (err) {
        actions.setErrors({
          email: 'Invalid email, please try again.',
        });
      }
      actions.setSubmitting(false);
    },
  });

  const regularInputClasses = 'border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500';
  const errorInputClasses = 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';

  const hasEmailError = formik.touched.email && formik.errors.email;

  return (

    <Container classes="bg-gray-50">
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <Panel>
            <div className="py-6 px-4">
              <div>
                <Link to="/">
                  <img
                    className="mx-auto h-12 w-auto"
                    src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg"
                    alt="CPipe"
                  />
                </Link>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Request a password reset link</h2>
                
              </div>
              {sent && (
                <div className="py-8">
                  <Alert type="success" title="Email sent!" body="Please check your email for the reset password link." />

                  <div className="text-center">
                    <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Ready to login?
                    </Link>
                  </div>
                </div>
              )}
              {!sent && (
                <form className="mt-8 space-y-6" onSubmit={formik.handleSubmit}>
                  <input type="hidden" name="remember" defaultValue="true" />
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                      <label htmlFor="email-address" className="sr-only">
                        Email address
                      </label>
                      <input
                        {...formik.getFieldProps('email')}
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className={`${hasEmailError ? errorInputClasses : regularInputClasses} appearance-none rounded-md relative block w-full px-3 py-2 border focus:outline-none focus:z-10 sm:text-sm`}
                        placeholder="Email address"
                      />
                    </div>
                    <div>
                      {hasEmailError && (
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {formik.errors.email}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                        Ready to login?
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                      </span>
                      Send Password Reset Link
                    </button>
                  </div>
                </form>
              )}
            </div>
          </Panel>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
