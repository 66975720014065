import React from 'react';
import { PlusSmIcon as PlusSmIconSolid } from '@heroicons/react/solid';

export default function AddButton({ buttonText, onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="group -ml-1 bg-white p-1 rounded-md flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500"
    >
      <span className="w-8 h-8 rounded-full border-2 border-dashed border-gray-300 flex items-center justify-center text-gray-400">
        <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />
      </span>
      <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
        {buttonText || 'Add'}
      </span>
    </button>
  );
}