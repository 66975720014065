import React from 'react';
import NumberFormat from 'react-number-format';

function DateInput({ formik, type, name, id, placeholder, hasError }) {
  const classes = hasError ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
  const ariaProps = hasError ? { 'aria-invalid': 'true', 'aria-describedby' : `${id}-error` } : { 'aria-describedby' : `${id}-description` };
  const valueChange = (val) => {
    const { formattedValue, value } = val;
    if (value === '') {
      formik.setFieldValue(name, null);
    } else {
      formik.setFieldValue(name, formattedValue);
    }
  }
  const defaultValue = formik.initialValues[name];
  return (
    <NumberFormat
      className={`block w-full rounded-md sm:text-sm ${classes}`}
      id={id}
      name={name}
      defaultValue={defaultValue || null}
      {...ariaProps}
      format="##/##/####"
      mask={['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y']}
      allowEmptyFormatting
      onValueChange={valueChange}
    />    
  );
}

export default DateInput;
