import { useState, useEffect, useContext, createContext } from 'react';
import { checkClaim, getCurrentUser, logout, isLoggedIn } from '../utils/auth';


export const AuthContext = createContext({
  signout: () => null,
  hasRole: (providedRoles) => null,
  getCurrentUser: () => null,
  isLoggedIn: () => null,
});

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [claims, setClaims] = useState(null);

  const signout = () => logout();

  // Receives an array of roles, returning true if any of them match the user's role
  const hasRole = (providedRoles) => checkClaim(providedRoles);

  return {
    getCurrentUser,
    isLoggedIn,
    hasRole,
    signout,
  };
}

// Auth provider component (this is boilerplate for context providers)
export function ProvideAuth({ children }) {
  const authData = useProvideAuth();
  return <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>;
}

// Define our hook for child components to use to get access to the auth object
export const useAuth = () => {
  return useContext(AuthContext);
};
