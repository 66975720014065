import React, { useState, useEffect, useCallback }  from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { useDebouncedCallback } from 'use-debounce';
import { findIndexById, classNames } from '../../../utils/helpers';
import BadgeWithRemove from '../../badges/badge-with-remove';


export default function ComboboxMultiselect({ formik, name, id, placeholder, hasError, options, performQuery }) {
  const [localOptions, setLocalOptions] = useState([]);
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState(null);

  const values = formik.values[name];

  const addValue = useCallback((newValue) => {
    const newValues = values ? [...values] : [];
    newValues.push(newValue);
    setValuesForField(newValues);
  });

  const removeValue = useCallback((toRemove) => {
    // Shouldn't happen...
    if (!values) {
      return;
    }
    const newValues = [...values];
    const indexToRemove = findIndexById(values, toRemove, 'value');
    newValues.splice(indexToRemove, 1);
    setValuesForField(newValues);
  });

  const setValuesForField = useCallback((newValues) => {
    // console.log('setting values', newValues);
    formik.setFieldValue(name, newValues);
  }, [formik, name]);


  const performSearch = async (q) => {
    // console.log('performing search');
    const result = await performQuery(q, values);
    setLocalOptions(result);
  };

  // Debounce the search for options so we don't do it too much
  const debouncedSearch = useDebouncedCallback((q) => performSearch(q), 250);

  // Whenever query updates, fetch our options
  useEffect(() => {
    debouncedSearch(query);
  }, [query]);

  // Whenever we make a selection, update our values
  useEffect(() => {
    if (selected) {
      addValue(selected);
    }
    // Then reset selected
    setSelected(null);
  }, [selected]);

  // console.log(localOptions, 'options');
  // console.log(values, 'values');

  // const titleClasses = hasError ? 'font-medium text-red-600' : 'font-medium text-gray-700';
  // const currentFieldValue = formik.values[name];
  return (

    <>
      <Combobox as="div" value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Combobox.Input
            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(selected) => selected && selected.value}
            placeholder={placeholder}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>

          {localOptions.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {localOptions.map((option) => (
                <Combobox.Option
                  key={option.value}
                  value={option}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected: isSelected }) => (
                    <>
                      <span className={classNames('block truncate', isSelected && 'font-semibold')}>{option.label}</span>

                      {isSelected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
      
      <div className="py-4 space-x-3 space-y-2">
        {!!values && values.map(value => <BadgeWithRemove text={value.label} onRemove={() => removeValue(value.value)} key={value.value} />)}
      </div>
      

      {/* <fieldset className="space-y-2 mt-1">
        {localOptions.map(({ value, label, subtitle }, i) => {
          const optionId = `${id}_${i}`;
          const ariaProps = subtitle ? { 'aria-describedby' : `${optionId}-subtitle` } : subtitle;
          const isChecked = currentFieldValue && currentFieldValue.length && currentFieldValue.indexOf(value) !== -1;
          return (
            <div key={optionId} className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  type="checkbox"
                  {...formik.getFieldProps(name)}
                  {...ariaProps}
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  id={optionId}
                  name={name}
                  value={value}
                  checked={isChecked}
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor={optionId} className={titleClasses}>
                  {label}
                </label>
                {!!subtitle && (
                  <p id={`${optionId}-subtitle`} className="text-gray-500">
                    {subtitle}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </fieldset> */}
    </>
  );
}
