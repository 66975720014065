import React from 'react';
import MDEditor from '@uiw/react-md-editor';

function Markdown({ formik, name, hasError }) {
  const classes = hasError ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300' : 'focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300';
  
  const updateValue = (val) => {
    formik.setFieldValue(name, val);
  };

  const value = formik.values[name];

  const commandsBlacklist = [
    'image',
    'hr',
    'strikethrough',
    'checked-list',
    'live',
    'fullscreen',
  ];

  
  return (
    <div className={`shadow-sm block w-full sm:text-sm border rounded-md ${classes}`}>
      <MDEditor
        value={value}
        onChange={updateValue}
        preview="edit"
        visiableDragbar={false}
        commandsFilter={(command) => {
          return commandsBlacklist.indexOf(command.name) === -1 ? command : false;
        }}
      />
      {/* <MDEditor.Markdown source={value} /> */}
    </div>
  );
}

export default Markdown;
