import React from 'react';

function Textarea({ formik, name, id, placeholder, hasError, rows }) {
  const classes = hasError ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300' : 'focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300';
  const ariaProps = hasError ? { 'aria-invalid': 'true', 'aria-describedby' : `${id}-error` } : { 'aria-describedby' : `${id}-description` };
  
  return (
    <textarea
      {...formik.getFieldProps(name)}
      {...ariaProps}
      className={`shadow-sm block w-full sm:text-sm border rounded-md ${classes}`}
      id={id}
      name={name}
      rows={rows || 3}
      placeholder={placeholder}
    />
  );
}

export default Textarea;
