import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import objEqual from 'fast-deep-equal/es6/react';
import Field from './field';
import Badge from '../badges/badge';

const FocusError = (formik) => {
  const { errors, isSubmitting, isValidating } = formik.formik;

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      let keys = Object.keys(errors);
      if (keys.length > 0) {
        const selector = `[for=${keys[0]}]`;
        const selector2 = `[name=${keys[0]}]`;
        const errorElement = document.querySelector(selector) || document.querySelector(selector2);
        if (errorElement) {
          // errorElement.focus();
          errorElement.scrollIntoView({ behavior: "smooth", block: 'start' });
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

/**
 * 
 * Example form config:
 * 
 * const testForm = {
  initialValues: {
    // email: 's@so.com',
    // fname: 'Steve Test',
    // role: '1',
    // lname: 'Test Last',
    // phone: '(301) 404-4875',
    // password: 'this is a password',
    // comments: 'testing default value',
    // terms: true,
    // birthday: null,
    // preferences: ['catering'],
    // img: 'http://funbowlingleagues.test/storage/uploads/images/elZHA3FiFRnDM1vBGIACcLSwDRB3mLDpA8SGyj0z.png',
  },
  validationSchema: Yup.object({
    // email: Yup.string().email('Invalid email address.').required('Email is a required field.'),
    // password: Yup.string().min(6, 'Must be at least 6 characters.').required('Password is a required field.'),
    // fname: Yup.string().required('First name is a required field.'),
    // role: Yup.mixed().required('Role is a required field.'),
    // preferences: Yup.array().min(1, 'You must select at least one option.'),
    // comments: Yup.string().required('You must provide some comments.'),
    // birthday: Yup.date().typeError('Must be a valid date.'), // is required by default, unless adding .nullable()
    // terms: Yup.boolean().oneOf([true], 'You must agree to the terms to continue.'),
    // img: Yup.string().required('You must upload an image.'),
    // phone: Yup.string()
    //   .test(
    //     'valid-phone',
    //     'Must be a valid phone number.',
    //     (val) => val === '' || typeof val === 'undefined' || validatePhone(val)
    //   )
    //   .required('Phone number is a required field.'),
    // lname: Yup.string().required('Last name is a required field.'),
  }),
  onSubmit: async (values, actions) => {},
  // fields: [
  //   {
  //     id: 'fname',
  //     name: 'fname',
  //     label: 'First name',
  //     type: 'text',
  //   },
  //   {
  //     name: 'lname',
  //     label: 'Last name',
  //     type: 'text',
  //   },
  //   {
  //     name: 'comments',
  //     label: 'Comments',
  //     type: 'textarea',
  //     placeholder: 'Provide some comments please...',
  //   },
  //   {
  //     name: 'role',
  //     label: 'Role',
  //     type: 'select',
  //     optionsUrl: '/options/roles',
  //   },
  //   {
  //     name: 'preferences',
  //     label: 'Preferences',
  //     type: 'checkboxes',
  //     options: [
  //       {
  //         value: 'warp',
  //         label: 'Warp Speed',
  //       },
  //       {
  //         value: 'catering',
  //         label: 'Catering',
  //         subtitle: 'Vegetarian options available.',
  //       },
  //       {
  //         value: 'solitude',
  //         label: 'Solitude',
  //       },
  //     ],
  //   },
  //   {
  //     id: 'phone',
  //     name: 'phone',
  //     label: 'Phone',
  //     type: 'phone',
  //     helpText: 'Must be a mobile phone.',
  //   },
  //   {
  //     id: 'email',
  //     name: 'email',
  //     label: 'Email',
  //     type: 'email',
  //     helpText: 'You will use this to login.',
  //   },
  //   {
  //     name: 'birthday',
  //     label: 'Birthday',
  //     type: 'date',
  //     helpText: 'Add your birthday so we can verify your age.',
  //   },
  //   {
  //     name: 'password',
  //     label: 'Password',
  //     placeholder: 'Enter a strong password.',
  //     type: 'password',
  //     helpText: 'Must be at least 6 characters.',
  //   },
  //   {
  //     name: 'terms',
  //     // label: 'HIDE ME',
  //     type: 'checkbox',
  //     title: 'I agree to the terms',
  //     subtitle: <a href="/">View complete terms &raquo;</a>,
  //     // helpText: 'You must accept the terms to continue',
  //   },
  //   {
  //     name: 'img',
  //     type: 'file',
  //     label: 'Cover photo',
  //     maxFiles: 1,
  //     isImage: true,
  //     helpText: 'Cover photo for the league.',
  //   }
  //   {
  //     name: 'img',
  //     type: 'cloudinary-upload',
  //     label: 'Cover photo',
  //     maxFiles: 1,
  //     isImage: true,
  //     helpText: 'Cover photo for the league.',
  //   }
  //   {
  //     name: 'images',
  //     type: 'cloudinary-multiple-upload',
  //     label: 'Cover photo',
  //     maxFiles: 5,
  //     isImage: true,
  //     helpText: 'Cover photo for the league.',
  //   }
      // {
      //   name: 'answers',
      //   label: 'Answer(s)',
      //   helpText: 'Please provide a list of acceptable answers.',
      //   type: 'text-list',
      //   placeholder: 'something for each text field',
      //   reorder: true/false to allow reordering
      // },
  // ],
  submit: {
    label: 'Create User',
  },
  reset: {
    label: 'Cancel Changes',
  },
  centerButton: true,
  observeChanges: false, // whether or not to allow saving if there's no changes detected
};
 */

function Buttons({ submit, reset, formik, noContainer, centerButton, changed, observeChanges }) {

  const { label: btnText } = submit;

  const disabled = formik.isSubmitting || (observeChanges && !changed);

  const btnClasses = disabled ? 'bg-indigo-400 focus:ring-indigo-400 hover:bg-indigo-500 cursor-not-allowed' : 'bg-indigo-600 focus:ring-indigo-600 hover:bg-indigo-700';
  const resetBtnClasses = disabled ? 'bg-gray-100 focus:ring-indigo-400 hover:bg-gray-200 cursor-not-allowed' : 'bg-white focus:ring-indigo-500 hover:bg-gray-50';

  const handleReset = (e) => {
    e.preventDefault();
    if (reset && reset.action) {
      reset.action(formik);
    }
    else {
      formik.resetForm();
    }
  };

  return (
    <div className={`py-3 ${noContainer ? '' : 'px-4 bg-gray-50 sm:px-6'} flex flex-row w-full justify-between`}>
      <div className={`${centerButton ? 'text-center' : 'text-left'} space-x-2`}>
      {!!reset && !!reset.label && (
        <button
          type="button"
          disabled={formik.isSubmitting}
          onClick={handleReset}
          className={` ${resetBtnClasses} inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {reset.label}
        </button>
      )}
        <button
          type="submit"
          disabled={formik.isSubmitting}
          className={`${btnClasses} border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {btnText}
        </button>
      </div>
      {!!observeChanges && !!changed && (
        <div className="text-right pt-1">
          <Badge color="yellow" large text="There are unsaved changes" />
        </div>
      )}
    </div>
  );
}

function Form({ initialValues, validationSchema, onSubmit, fields, submit, noContainer, reset, centerButton = true, observeChanges = false }) {
  const [changed, setChanged] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  // Optionally track whether or not the form has it's values changed
  useEffect(() => {
    // If we have a change listener, let's see if we have changed
    if (observeChanges) {
      const hasFormChanged = !objEqual(initialValues, formik.values);
      if (changed !== hasFormChanged) {
        setChanged(hasFormChanged);
      }
    }
  });

  return (
    <form className="" onSubmit={formik.handleSubmit}>
      <div className={`${noContainer ? '' : 'shadow sm:rounded-md sm:overflow-hidden'}`}>
        <div className={`bg-white py-6 px-4 space-y-6 ${noContainer ? '' : 'sm:p-6 border border-gray-100'}`}>
          {fields.map((field) => {
            return <Field field={field} formik={formik} key={field.name} />;
          })}
        </div>
        <Buttons changed={changed} observeChanges={observeChanges} centerButton={centerButton} submit={submit} reset={reset} noContainer={noContainer} formik={formik} />
      </div>
      <FocusError formik={formik} />
    </form>
  );
}

export default Form;
