import React, { useState, useEffect }  from 'react';
import { getApiRequest } from '../../../utils/request';
import AddButton from '../../buttons/add-button';
import FormModal from '../../overlays/form-modal';

function AddHandler({ addButtonText, addForm, loadOptions, addTitle, addBody }) {
  const [showModal, setShowModal] = useState(false);
  if (!addForm) {
    return null;
  }

  addForm.successHandler = (values, actions) => {
    actions.resetForm();
    setShowModal(false);
    // Reload the options
    loadOptions();
  };

  return (
    <>
      <AddButton onClick={() => setShowModal(true)} buttonText={addButtonText} />
      <FormModal
        form={addForm}
        open={showModal}
        cancel={() => setShowModal(false)}
        title={addTitle}
        body={addBody}
      />
    </>
  )
}

function Checkboxes({ formik, name, id, hasError, options, optionsUrl, addForm, addButtonText, addTitle, addBody, getOptionsUrl, dynamicOptions }) {
  const [localOptions, setLocalOptions] = useState([]);
  const [lastOptionsUrl, setLastOptionsUrl] = useState(null);

  const remoteLoadOptions = async (url) => {
    setLastOptionsUrl(url);
    if (!url) {
      setLocalOptions([]);
      return;
    }
    try {
      const results = await getApiRequest(url);
      if (results.options) {
        setLocalOptions(results.options.map(option => ({ value: `${option.value}`, label: option.label })));
      }
    } catch (err) {
      console.log(err, 'error fetching options');
    }
  };

  useEffect(() => {
    // If the options url is derived, we want to derive it, skip this step
    if (getOptionsUrl) {
      return;
    }
    if (options) {
      setLocalOptions(options);
    } else if (optionsUrl) {
      remoteLoadOptions(optionsUrl);
    }
  }, [optionsUrl, options, setLocalOptions, getOptionsUrl]);


  // In case we have dynamic options, we need to potentially re-render them each time.  Pass existing options and form values to the function
  useEffect(() => {
    if (dynamicOptions) {
      dynamicOptions(formik, localOptions, setLocalOptions);
    }
  });

  // If the local options url has changed, it means that we are deriving it.  If it's new, load options
  const localOptionsUrl = getOptionsUrl && getOptionsUrl(formik);
  useEffect(() => {
    if (getOptionsUrl && lastOptionsUrl !== localOptionsUrl) {
      remoteLoadOptions(localOptionsUrl);
    }
  }, [localOptionsUrl, getOptionsUrl]);


  const titleClasses = hasError ? 'font-medium text-red-600' : 'font-medium text-gray-700';
  const currentFieldValue = formik.values[name];
  return (
    <>
      <fieldset className="space-y-2 mt-1">
        {localOptions.map(({ value, label, subtitle }, i) => {
          const optionId = `${id}_${i}`;
          const ariaProps = subtitle ? { 'aria-describedby' : `${optionId}-subtitle` } : subtitle;
          const isChecked = currentFieldValue && currentFieldValue.length && currentFieldValue.indexOf(value) !== -1;
          return (
            <div key={optionId} className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  type="checkbox"
                  {...formik.getFieldProps(name)}
                  {...ariaProps}
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  id={optionId}
                  name={name}
                  value={value}
                  checked={isChecked}
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor={optionId} className={titleClasses}>
                  {label}
                </label>
                {!!subtitle && (
                  <p id={`${optionId}-subtitle`} className="text-gray-500">
                    {subtitle}
                  </p>
                )}
              </div>
            </div>
          );
        })}
        <AddHandler
          loadOptions={remoteLoadOptions}
          addForm={addForm}
          addButtonText={addButtonText}
          addTitle={addTitle}
          addBody={addBody}
        />
      </fieldset>
    </>
  );
}

export default Checkboxes;
