// @ts-nocheck
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/private-route';
import AdminContainer from './components/layout/admin/container';
import Home from './pages/home';
import ThankYou from './pages/thank-you';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import ForgotPassword from './pages/forgot-password';
import Logout from './pages/logout';
import AdminDashboard from './pages/admin/dashboard';
import AdminUsers from './pages/admin/users';
import AdminUser from './pages/admin/user';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route path="/dashboard" element={<PrivateRoute component={Dashboard}/>} />

      <Route element={<AdminContainer />} path="/admin">
        <Route index element={<PrivateRoute roles={['admin']} component={AdminDashboard} />} />
        <Route path="users" element={<PrivateRoute roles={['admin']} component={AdminUsers} />} />
        <Route path="user" element={<PrivateRoute roles={['admin']} component={AdminUser} />} />
        <Route path="user/:id" element={<PrivateRoute roles={['admin']} component={AdminUser} />} />
      </Route>

      <Route path="/:code" element={<Home />} />

    </Routes>
  );
}
