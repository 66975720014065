import React from 'react';
import SimpleNotification from '../../alerts/simple-notification';

export default function Container({ children, classes = '' }) {
  return (
    <div className={`font-sans min-h-screen ${classes}`}>
      {children}
      <SimpleNotification />
    </div>
  );
}
