import AppRoutes from './app-routes';
import { ProvideNotifications } from './contexts/notifications-provider';
import { ProvideBreadcrumbs } from './contexts/breadcrumbs-provider';
import { ProvideAuth } from './contexts/auth-provider';


function App() {
  return (
    <ProvideAuth>
      <ProvideNotifications>
        <ProvideBreadcrumbs>
          <AppRoutes />
        </ProvideBreadcrumbs>
      </ProvideNotifications>
    </ProvideAuth>
  );
}

export default App;
