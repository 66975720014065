import React from 'react';

function HelpText({ id, helpText, hasError, errorText }) {
  if (!helpText && !errorText) {
    return null;
  }
  if (hasError && errorText) {
    return (
      <p id={`${id}-description`} className="mt-2 text-sm text-red-600">
        {errorText}
      </p>
    );
  }
  return (
    <p id={`${id}-description`} className="mt-2 text-sm text-gray-500">
      {helpText}
    </p>
  );
}

export default HelpText;
