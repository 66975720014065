import React from 'react';
import { Link } from 'react-router-dom';


function Links({ links }) {
  if (!links || !links.length) {
    return null;
  }

  return (
    <div className="mt-3 flex sm:mt-0 sm:ml-4">
      {links.map((item, i) => {
        const { secondary, href, label, onClick } = item;
        const hasMarginLeft = links.length > 1 && i > 0;
        const classes = secondary
          ? 'inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          : 'inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';

        const className = `${hasMarginLeft ? 'ml-3 ' : ''} ${classes}`;
        if (onClick) {
          return (
            <button
              key={label}
              onClick={onClick}
              className={className}
            >
              {label}
            </button>
          );
        }
        return (
          <Link
            key={label}
            to={href}
            className={className}
          >
            {label}
          </Link>
        );
      })}
    </div>
  );
}

export default function SectionTitle({ title, links }) {
  return (
    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between  mb-4">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
      {!!links && !!links.length && <Links links={links} />}
    </div>
  );
}
