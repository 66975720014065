import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useBreadcrumbs } from '../../contexts/breadcrumbs-provider';
import Heading from '../../components/layout/admin/heading';
import AutoForm from '../../components/form/auto-form';
import Frame from '../../components/layout/frame';

const AdminUser = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { id } = useParams();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/admin/users',
        label: 'Users',
      },
      {
        href: `/admin/user${id ? `/edit?id=${id}` : ''}`,
        label: `${id ? 'Update' : 'Create'} User`,
      },
    ]);
  }, [id]);


  const userForm = {
    type: 'post',
    fetchRoute: id ? `/user/${id}` : null,
    fetchHydrate: (fetched => fetched.user),
    fetchErrorRedirect: '/admin/users',
    fetchErrorMessage: 'There was an error while attempting to load the requested user.',
    postRoute: `/user${id ? `/${id}` : ''}`,
    successRedirect: '/admin/users',
    successNotification: 'The user was saved.',
    fields: [
      {
        name: 'displayName',
        label: 'Name',
        type: 'text',
        required: true,
      },
      {
        name: 'role',
        label: 'Role',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 'admin',
            label: 'Admin',
          },
          {
            value: 'editor',
            label: 'Editor',
          },
          {
            value: 'sme',
            label: 'Subject Matter Expert',
          },
        ],
        required: true,
      },
      {
        id: 'email',
        name: 'email',
        label: 'Email',
        type: 'email',
        helpText: 'This will be used to login.',
        required: true,
      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Enter a strong password.',
        type: 'password',
        helpText: `Must be at least 8 characters. ${id ? 'Leave blank to ignore the password and just update the user.' : ''}`,
        validations: () => {
          if (!id) {
            return Yup.string().min(8, 'Must be at least 8 characters.').required('Password is a required field.');
          } else {
            return Yup.string().min(8, 'Must be at least 8 characters.');
          }
        },
        initialValue: () => {
          return '';
        },
      },
    ],
    submitLabel: `${id ? 'Update' : 'Create'} User`,
  };

  return (
    <Frame>
      <Heading title={`${id ? 'Update' : 'Create'} User`} />
      <div className="max-w-2xl mx-auto py-12">
        <AutoForm {...userForm} />
      </div>
    </Frame>
  );
}

export default AdminUser;
