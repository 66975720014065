import axios from 'axios';
import { getCurrentJwt, logout } from './auth';

axios.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        logout(() => {
          window.location.href = '/login';
        });
        return;
      }
    }
    return Promise.reject(error);
  }
);

async function getHeaders() {
  const headers = {};
  const token = getCurrentJwt();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

async function makeRequest(method, url, data) {
  const headers = await getHeaders();
  if (method === 'get' || method === 'delete') {
    // @ts-ignore
    return axios({
      method,
      url,
      headers,
    });
  }
  // @ts-ignore
  return axios({
    method,
    url,
    data,
    headers,
  });
}

export const apiUrl = process.env.REACT_APP_API_URL;
export function getRequest(url) {
  return makeRequest('get', url);
};
export function postRequest(url, data) {
  return makeRequest('post', url, data);
}
export function putRequest(url, data) {
  return makeRequest('put', url, data);
}
export function deleteRequest(url) {
  return makeRequest('delete', url);
};

export function getApiRequest(url) {
  return makeRequest('get', `${apiUrl}${url}`);
};
export function postApiRequest(url, data) {
  return makeRequest('post', `${apiUrl}${url}`, data);
}
export function putApiRequest(url, data) {
  return makeRequest('put', `${apiUrl}${url}`, data);
}
export function deleteApiRequest(url) {
  return makeRequest('delete', `${apiUrl}${url}`);
};
