import { Link } from 'react-router-dom';
import Frame from '../../components/layout/frame';

export default function AdminDashboard() {
  return (
      <Frame>
        <div className="py-24 text-center">
          <h1 className="text-4xl">
           This will be the admin dashboard.
          </h1>
          <p className="my-24"><Link to="/logout">Logout</Link></p>
        </div>
      </Frame>
  );
}