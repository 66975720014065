import moment from 'moment';
import { isValidPhoneNumber, AsYouType } from 'libphonenumber-js';

export function validatePhone(value) {
  return isValidPhoneNumber(value || '', 'US');
}

export function formatPhoneFromServer(value) {
  if (!value) {
    return null;
  }

  const asYouType = new AsYouType('US');
  return asYouType.input(value.replace('+1', ''));
}


export function dateLeadingZero(num) {
  const absNum = Math.abs(num);
  if (absNum < 10) {
    return `0${absNum}`;
  }
  return absNum;
}


export function formatUtcUnixDate(timestamp, format = 'll') {
  return moment.unix(timestamp).utc().format(format);
}

// See: https://github.com/iamcal/lib_timezones/blob/master/lib/lib_timezones.js
export function timezoneOptions() {
  return [
    {
      value: '',
      label: 'Select...',
    },
    {
      value: 'America/New_York',
      label: 'Eastern Time (US)',
    },
    {
      value: 'America/Chicago',
      label: 'Central Time (US)',
    },
    {
      value: 'America/Denver',
      label: 'Mountain Time (US)',
    },
    {
      value: 'America/Los_Angeles',
      label: 'Pacific Time (US)',
    },
    {
      value: 'America/Phoenix',
      label: 'Arizona',
    },
    {
      value: 'America/Anchorage',
      label: 'Alaska',
    },
    {
      value: 'Pacific/Honolulu',
      label: 'Hawaii',
    },
  ];
}

export function timezonePrettyName(tz) {
  const timezones = timezoneOptions();
  for (let i = 0; i < timezones.length; i++) {
    if (tz === timezones[i].value) {
      return timezones[i].label;
    }
  }
  return tz;
}


const states = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District Of Columbia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming',
};

export function statesOptions(hideSelect) {
  const keys = Object.keys(states);
  const ret = hideSelect ? [] : [{
    value: '',
    label: 'Select...',
  }];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    ret.push({
      value: key,
      label: states[key],
    });
  }
  return ret;
}

export function statePrettyName(abbr) {
  return states[abbr];
}


export function capFirstLetter(str) {
  if (!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function truncateString(str, length) {
  if (!str) {
    return str;
  }
  if (str.length <= length) {
    return str;
  }
  return `${str.slice(0, length)}...`;
}

export function roundLikePhp(num, dec) {
  const num_sign = num >= 0 ? 1 : -1;
  return parseFloat((Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec));
}

// Function to take an input ref and copy to clipboard it's contents
export function copyToClipboard(ref) {
  if (ref && ref.current) {
    ref.current.select();
    document.execCommand('copy');
    ref.current.setSelectionRange(0, 0);
    ref.current.blur();
    return true;
  }
  return false;
}


export function pluralize(str, num, plural) {
  const absNum = Math.abs(num);
  if (absNum === 1) {
    return str;
  }
  return plural || `${str}s`;
}

// Helper function to resize an image that lives in cloudinary
// For instance:
// https://res.cloudinary.com/xxxxxx/image/upload/v1642886217/xxxxxxxxxx.png
// Becomes (with width 200, quality 80)
// https://res.cloudinary.com/xxxxxx/image/upload/w_200,q_80/xxxxxxxxxx.png
export function resizeImg({ url, width, height, quality }) {
  if (!url) {
    return null;
  }
  // Isolate the url portion after uploads and before the filename
  const urlParts = url.split('/upload/');
  const afterUpload = urlParts[1];
  if (!afterUpload) {
    return url;
  }
  const filenameParts = afterUpload.split('/');
  if (filenameParts.length !== 2) {
    return url;
  }
  const toReplace = filenameParts[0];

  const replacementParts = [];
  if (width) {
    replacementParts.push(`w_${width}`);
  }
  if (height) {
    replacementParts.push(`h_${height}`);
  }
  if (quality) {
    replacementParts.push(`q_${quality}`);
  }
  if (!replacementParts.length) {
    return url;
  }
  const replacementString = replacementParts.join(',');
  return url.replace(toReplace, replacementString);
}


// Fix the index of an item by it's id
export function findIndexById(items, id, idKey = 'id', allowNull = false) {
  if (!items || (!allowNull && !id)) {
    return -1;
  }
  for (let i = 0; i < items.length; i++) {
    if (items[i][idKey] === id) {
      return i;
    }
  }
  return -1;
}


export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function ucwords(str) {
  return str.toLowerCase().replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
  	function(s){
  	  return s.toUpperCase();
	});
};
