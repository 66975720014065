import jwtDecode from 'jwt-decode';
import axios from 'axios';
import moment from 'moment';

const getUser = () => { 
  try {
    const user = window.localStorage.authUser ? JSON.parse(window.localStorage.authUser) : {};
    return user;
  } catch (err) {
    // The user is malformed for some reason...
    setUser({});
    return {};
  }
};
const getJwt = () => { 
  try {
    const token = window.localStorage.authJwt ? JSON.parse(window.localStorage.authJwt).token : null;
    return token;
  } catch (err) {
    // The jwt is malformed for some reason...
    setJwt(null);
    return null;
  }
};

const checkJwtValidity = (jwt) => {
  const decoded = jwtDecode(jwt);
  if (!decoded) return false;
  const now = moment().unix();
  if (now >= decoded.exp) return false;
  return true;
}

export const checkClaim = (permArray) => {
  const jwt = getJwt();
  if (!jwt) return false;
  const decoded = jwtDecode(jwt);
  if (!decoded) return false;
  if (!checkJwtValidity(jwt)) return false;
  const { claims } = decoded;
  if (!claims) return false;
  // Now check for the permission in the claims array
  // const permArray = perms.split('|');
  for (let i = 0; i < permArray.length; i++) {
    const perm = permArray[i];
    if (claims.indexOf(perm) !== -1) return true;
  }
  return false;
}

export const setUser = user => (window.localStorage.authUser = JSON.stringify(user));
export const setJwt = jwt => {
  if (!jwt) {
    window.localStorage.authJwt = JSON.stringify({});
  }
  else {
    window.localStorage.authJwt = JSON.stringify({ token: jwt });
  }
};

export const isLoggedIn = () => {
  const jwt = getJwt();
  if (!jwt) return false;
  if (!checkJwtValidity(jwt)) return false;
  const user = getUser();
  return !!user && !!user.id;
}

export const getCurrentUser = () => isLoggedIn() && getUser();
export const getCurrentJwt = () => getJwt();

let isLoggingOut = false;
export const logout = async callback => {
  if (!isLoggingOut) {
    isLoggingOut = true;
    const token = getCurrentJwt();
    if (token) {
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        await axios({
          method: 'post',
          url: `${process.env.GATSBY_API_URL}/auth/log-out`,
          headers,
        });
      } catch (err) {
        console.log(err, 'already logged out');
      }
    }
    setUser({});
    setJwt(null);
    isLoggingOut = false;
    if (callback) {
      callback();
    }
  }
}

export const translateRoleIdToName = (id) => {
  switch (id) {
    case '1':
    case 1:
      return "Admin";

  }
}