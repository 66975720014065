import React from 'react';

function Checkbox({ formik, name, id, hasError, title, subtitle }) {
  const titleClasses = hasError ? 'font-medium text-red-600' : 'font-medium text-gray-700';
  const ariaProps = subtitle ? { 'aria-describedby' : `${id}-subtitle` } : subtitle;
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          type="checkbox"
          {...formik.getFieldProps(name)}
          {...ariaProps}
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          id={id}
          name={name}
          checked={!!formik.values[name]}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className={titleClasses}>
          {title}
        </label>
        {!!subtitle && (
          <p id={`${id}-subtitle"`} className="text-gray-500">
            {subtitle}
          </p>
        )}
      </div>
    </div>
  );
}

export default Checkbox;
