import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { getCurrentJwt } from '../../../utils/auth';
import Loading from '../../data/loading';

function ShowError({ error }) {
  return (
    <p className="mt-2 text-sm text-red-600">
      {error}
    </p>
  );
}


function File({ formik, name, id, hasError, maxFiles, multiple, maxSize, isImage, accept }) {
  const [initialized, setInitialized] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Set our default file IF we have one
    const initialValue = formik.initialValues[name];
    if (initialValue) {
      setFile(initialValue);
    }
    setInitialized(true);
  }, []);

  const setFileForField = useCallback((url) => {
    formik.setFieldValue(name, url);
    setFile(url);
  }, [formik, name, setFile]);

  const onDropAccepted = useCallback(async (acceptedFiles) => {
    setError(null);
    setUploading(true);
    setUploadProgress(5);
    const formData = new FormData();
    acceptedFiles.forEach(file => {
      formData.append('file', file);
    });

    const url = `${process.env.GATSBY_API_URL}/upload/${isImage ? 'image' : 'file'}`;

    try {
      const jwt = await getCurrentJwt();
      const uploadResult = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${jwt}`,
        },
        data: formData,
        url,
        onUploadProgress: (ev) => {
          const progress = ev.loaded / ev.total * 100;
          setUploadProgress(Math.round(progress));
        },
      });

      setUploadProgress(0);
      setUploading(false);
      // console.log(uploadResult, 'the upload result');
      const { publicUrl } = uploadResult;
      setFileForField(publicUrl);
    } catch (err) {
      console.log(err, 'this is the error');
      setUploadProgress(0);
      setUploading(false);
      setError('There was an error uploading the file, please try again.');
    }
  }, [setError, setUploading, setUploadProgress, setFileForField, isImage]);

  const onDropRejected = useCallback((e) => {
    // console.log(e, 'failure');
    setError(e[0].errors[0].message);
  }, [setError]);

  // Setup the props to pass to dropzone
  let descriptionText = '';
  const dropzoneProps = { onDropAccepted, onDropRejected, noClick: true };
  if (maxFiles) {
    dropzoneProps.maxFiles = maxFiles;
  }
  if (isImage) {
    dropzoneProps.accept = ['image/png', 'image/gif', 'image/jpeg'];
    descriptionText = 'PNG, JPG, or GIF';
  } else if (accept) {
    dropzoneProps.accept = accept;
  }
  if (multiple) {
    dropzoneProps.multiple = multiple;
  }
  if (maxSize) {
    dropzoneProps.maxSize = maxSize;
  }
  const { getRootProps, getInputProps } = useDropzone(dropzoneProps);


  // Don't do anything if we aren't initialized
  if (!initialized) {
    return null;
  }

  if (uploading) {
    return (
      <div className="py-12">
        <Loading noPadding text={`Uploading ${Math.round(uploadProgress)}%`} />
      </div>
    );
  }

  if (file) {
    return (
      <div>
        <div className="p-2 rounded bg-gray-100">
          <div className="mt-1 flex items-center">
            <span className="inline-block bg-gray-100 rounded overflow-hidden w-full max-h-48">
              <img className="h-full object-cover w-full" alt="Uploaded file" src={file} />
            </span>
            <button
              type="button"
              className="ml-5 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => setFileForField('')}
            >
              Remove
            </button>
          </div>
        </div>
        <ShowError error={error} />
      </div>
    );
  }

  return (
    <div>
      <div className="mt-1 border-2 border-gray-300 border-dashed rounded-md px-6 pt-5 pb-6 flex justify-center" {...getRootProps()}>
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor={id}
              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <span>Upload a file</span>
              <input id={id} name={name} type="file" className="sr-only" {...getInputProps()} />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-600">{descriptionText}</p>
        </div>
      </div>

      <ShowError error={error} />
    </div>
  );

}

export default File;
