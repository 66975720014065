import Container from '../components/layout/default/container';
import headerImage from '../assets/header-photo.png';
import Panel from '../components/common/panel';


export default function ThankYou() {

  return (
    <Container classes="bg-gray-50">
      <div className="py-20 max-w-3xl mx-auto">
        <img src={headerImage} className="w-full" alt="Join Our Team" />
        
        <div className="max-w-2xl mx-auto my-10">
          <Panel>
            <div className="py-10 px-4">
              <h1 className="text-4xl text-gray-800 font-serif text-center mb-8">
                Submission Successful!
              </h1>
              <p className="text-gray-700 text-center text-xl">
                Thanks!  We have received your application.  We will reach out soon!
              </p>
            </div>
          </Panel>
        </div>
      </div>
    </Container>
  );
}